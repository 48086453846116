import { template as template_9c3327ace4284628b52231b30ff771e7 } from "@ember/template-compiler";
const FKLabel = template_9c3327ace4284628b52231b30ff771e7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
