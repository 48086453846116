import { template as template_91b8e23326ff47d6b6fc90f5d8190557 } from "@ember/template-compiler";
const WelcomeHeader = template_91b8e23326ff47d6b6fc90f5d8190557(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
